import "./helpers/scrollToTop";
import "./front-page/hearo-section";
import "./front-page/info-section";
import "./front-page/circle-section/circle-section-index";
import "./front-page/products-section";
import "./front-page/lower";
import "./front-page/contact-section";

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual'
}




