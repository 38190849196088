// screen sizes\/\/\/\/

const animConsts = {
  xxl: 2559,
  xl: 1919,
  lg: 1420,
  md : 1006,
  sm : 767,
  xs : 599,
  offset: 0,
  circleHd: 1183,
  globalScreenX : window.outerWidth,
  pageDown: document.querySelector("body").scrollHeight,
  videoCircleWideScreenSources: [["video/circle-WideScreen.mp4", "video/mp4"],["video/circle-WideScreen.webm", "video/webm"],["video/circle-WideScreen.ogv", "video/ogg"]],
  videoCircleSources: [["video/circle.mp4", "video/mp4"],["video/circle.webm", "video/webm"],["video/circle.ogv", "video/ogg"]],
  videoStripSources: [["video/strip.mp4", "video/mp4"],["video/strip.webm", "video/webm"],["video/strip.ogv", "video/ogg"]],
}
function updateSize(){
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    animConsts.globalScreenX = window.outerWidth
   } else {
    animConsts.globalScreenX = window.innerWidth
   }
}

window.addEventListener('resize', updateSize)
document.addEventListener('DOMContentLoaded', updateSize)

export default animConsts;
