import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animConsts  from "../constants";

gsap.registerPlugin(ScrollTrigger);


const imgs = document.getElementsByClassName("products-section__card__img");
const cards = document.getElementsByClassName("products-section__card");


// ----------block A----------//

function blockAhd(){
  const tlA = gsap.timeline({
    scrollTrigger: {
      trigger: cards[0],
      start: `top bottom`,
      end: `bottom top`,
      scrub: 1,
      // markers: true
    }
  });
  tlA.fromTo(imgs[0], {
      y: -160,
      x: -120,
    },{
      y: -500,
      x: -220,
    })
    return tlA
}
function blockAmd(){
  const tlAHd = gsap.timeline({
    scrollTrigger: {
      trigger: cards[0],
      start: `top bottom`,
      end: `bottom top`,
      scrub: 1,
      // markers: true
    }
  });
  tlAHd.fromTo(imgs[0],{
    y: "250",
    x: 30,
  },{
    y: "-120",
    x: 0
  })
    return tlAHd
}
// ----------block B----------//
function blockBhd(){
  const tlB = gsap.timeline({
    scrollTrigger: {
      trigger: cards[1],
      start: "top bottom",
      end: "bottom top",
      scrub: 1,
      // markers: true
    }
  });
  tlB.fromTo(imgs[1], {
      y: -150,
      x: -30,
    },{
      y: -400,
      x: 100,
    })
    return tlB
}
function blockBmd(){
  const tlB = gsap.timeline({
    scrollTrigger: {
      trigger: cards[1],
      start: "top bottom",
      end: "bottom top",
      scrub: 1,
      // markers: true
    }
  });
  tlB.fromTo(imgs[1], {
      y: 400,
      x: -175,
      scale: 2.5
    },{
      y: 250,
      x: -75,
      scale: 2.5
    })
    return tlB
}
// ----------block C----------//

function blockChd(){
  const tlC = gsap.timeline({
    scrollTrigger: {
      trigger: cards[2],
      start: "top bottom",
      end: "bottom top",
      scrub: 1,
      //  markers: true,
    }
  });
  tlC.fromTo(imgs[2], {
      y: -280,
      x: -320,
      scale: 0.8
    },{
      y: -420,
      x: -320,
      scale: 0.8
    })
    return tlC
}
function blockCmd(){
  const tlC = gsap.timeline({
    scrollTrigger: {
      trigger: cards[2],
      start: "top bottom",
      end: "bottom top",
      scrub: 1,
      //  markers: true,
    }
  });
  tlC.fromTo(imgs[2], {
      y: -10,
      x: -50,
    },{
      y: -100,
      x: -50,
    })
    return tlC
}
// ---------resize check-------//
function runProductsBySize(){
  gsap.set(".products-section__card__img",{clearProps: "all"});
    if(animConsts.globalScreenX < animConsts.md){
      setTimeout(()=>{
        blockAmd();
        blockBmd();
        blockCmd();
      },5)
    } else {
      setTimeout(()=>{
      blockAhd();
      blockBhd();
      blockChd();
      },5)
    }
  }

  
document.addEventListener('DOMContentLoaded', runProductsBySize)






