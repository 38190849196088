import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animConsts  from "../constants";

gsap.registerPlugin(ScrollTrigger);

// ------flag------//
let infoSectionDone = false;
// ------flag------//

const tl = gsap.timeline({
  scrollTrigger: {
    trigger: ".info-section",
    start: "top bottom",
    end: "top 20%",
    scrub: 1,
    onLeave: ()=>{
     infoSectionDone = true;
     setTimeout(()=>{
       tl.kill(true);
       ScrollTrigger.refresh();
     },1000)
     },
    onScrubComplete: ({progress})=>{
      if(progress === 1){
       tl.kill(true);
       ScrollTrigger.refresh();
       gsap.set(".info-section, .info-section>*",{clearProps: "all"});
       }
    }
  }
});

function infoInHd(){
  tl.clear()
  gsap.set(".info-section, .info-section>*",{clearProps: "all"});
 tl.from(".info-section", {
    paddingBottom: 500,
    ease: "power1.in",
    duration: 2
   }, "0")
 tl.from(".info-section__anim", {
    scale: 1.3,
    ease: "power1.inOut",
    duration: 1.4,
 }, "-=1.7")
 tl.from(".info-section__strip", {
    scaleY: 10,
    ease: "power1.in",
    duration: 1.5,
 }, "-=1.5")
}

function infoInMd(){
  tl.clear()
  gsap.set(".info-section, .info-section>*",{clearProps: "all"});
 tl.from(".info-section", {
    paddingBottom: 500,
    ease: "power1.in",
    duration: 2
   }, "0")
 tl.from(".info-section__anim", {
    scale: 1.1,
    ease: "power1.inOut",
    duration: 1.4,
 }, "-=1.7")
 tl.from(".info-section__strip", {
    scaleY: 10,
    ease: "power1.in",
    duration: 1.5,
 }, "-=1.5")
}

// scroll to info section

const discoverMore = document.querySelector(".menu__point");


function goToBlockB(){
  infoSectionDone = true;
  tl.clear()
  gsap.set(".info-section, .info-section>*",{clearProps: "all"});
  gsap.to(window,{
    duration: 1,
    scrollTo: ".info-section",
    ease: "power3.out"
  });
}


discoverMore.addEventListener("click", goToBlockB)

// ---------resize check-------//
function getSizeInfo(){
  if(infoSectionDone)return
    if(animConsts.globalScreenX < animConsts.md){
      infoInMd();
    } else {
      infoInHd();
    }
  }


window.addEventListener('resize', getSizeInfo)
document.addEventListener('DOMContentLoaded', getSizeInfo)
