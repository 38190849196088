import "./preloader";
import sayHello from "./lib/sayHello";
import "./lib/swiper";
import "./amixy-animations/index";


sayHello();

// Timeline animation
const heroVideo = document.querySelector(".js-hero-video");
const timeline = document.querySelector(".js-timeline");
if (heroVideo) {
  heroVideo.ontimeupdate = () => {
    const percentage = (heroVideo.currentTime / heroVideo.duration) * 100;
    timeline.style.width = `${percentage}%`;
  };
}

// Scroll top button
const scrollTopBtn = window.document.querySelector("#scroll-top-btn");
if (scrollTopBtn) {
  scrollTopBtn.onclick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
}
