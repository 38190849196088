// @todo
// - texture
// - particles flowing with same position calc
// - fit to fullscreen

import * as THREE from "three";
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

// import * as dat from "dat.gui";
// import gsap from "gsap";
import fragment from "./shader/fragment.glsl";
import fragmentParticles from "./shader/fragmentParticles.glsl";
import vertex from "./shader/vertex.glsl";
import vertexParticles from "./shader/vertexParticles.glsl";

const t = 'img/texture.jpg'


export default class Sketch {
  constructor(options) {
    this.scene = new THREE.Scene();
    this.scene1 = new THREE.Scene();

    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true,
      antialias: true,
      alpha: true,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
    this.renderer.setSize(this.width, this.height);

    this.renderer.autoClear = false;

    this.container.appendChild(this.renderer.domElement);
    this.camera = new THREE.PerspectiveCamera(
      70,
      this.width/this.height,
      0.1,
      10
    );


    // const frustumSize = 4;
    // const aspect = this.width/this.height;

    this.camera.position.set(0, 0, 4);


    this.camera1 = new THREE.OrthographicCamera( -2,2,-0.5,0.5, -100, 100 );
    this.camera1.position.set(0, 0, 4);



    this.camera1 = new THREE.OrthographicCamera( -2,2,-0.5,0.5, -100, 100 );
    this.camera1.position.set(0, 0, 4);

    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.time = 0;

    this.isPlaying = true;

    this.baseTexture = new THREE.WebGLRenderTarget( this.width, this.height, {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat
    } );

    this.addObjects();
    this.createParticles()
    this.resize();
    this.render();
    this.setupResize();
    this.settings();

  }

  settings() {
    const that = this;
    this.settings = {
      progress: 0.0,
      colorsProgress: 0.0,
      speed: 1.0,
    };
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera1.aspect = this.width / this.height;

    const width = 4;
    const height = width/this.camera.aspect;
    const dist  = this.camera.position.z;
    this.camera.fov = 2*(180/Math.PI)*Math.atan(height/(2*dist));
    this.camera1.fov = 2*(180/Math.PI)*Math.atan(height/(2*dist));



    this.camera.updateProjectionMatrix();
    this.camera1.updateProjectionMatrix();


  }


  createParticles(){
    const particlesGeometry = new THREE.BufferGeometry();

    const color = new THREE.Color();
    const components = [];

    const count = 200;
    const positions = new Float32Array(count * 3);
    const randoms = new Float32Array(count);
    const randoms1 = new Float32Array(count);
    const size = new Float32Array(count );

    for (let i = 0; i < count; i++) {
      positions[3*i] = 0;
      positions[3*i+1] = 1.*Math.random() - 0.5;
      positions[3*i+2] = 0
      size[i] = Math.random();
      randoms[i] = Math.random();
      randoms1[i] = Math.random();
    }


    particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );

    particlesGeometry.setAttribute(
      "size",
      new THREE.BufferAttribute(size, 1, true)
    );

    particlesGeometry.setAttribute(
      "offset",
      new THREE.BufferAttribute(randoms, 1, true)
    );

    particlesGeometry.setAttribute(
      "random",
      new THREE.BufferAttribute(randoms1, 1, true)
    );
    this.particlesMaterial = new THREE.ShaderMaterial({
      uniforms: {
        time: {value: 0},
        progress: {value: 0},
        speed: { value: 1 },
      },
      fragmentShader: fragmentParticles,
      vertexShader: vertexParticles,
      transparent: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
    })

    this.particles = new THREE.Points(particlesGeometry, this.particlesMaterial);

    this.scene1.add(this.particles);
  };

  addObjects() {
    const that = this;

    const texture = new THREE.TextureLoader().load(t);


    texture.wrapT = THREE.RepeatWrapping;
    texture.wrapS = texture.wrapT;

    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        progress: { value: 0 },

        colorsProgress: { value: 1 },
        uTexture: { value: texture },
        uTexture1: { value: null },
        resolution: { value: new THREE.Vector4() },
      },

      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.geometry = new THREE.PlaneGeometry(1, 1, 100, 100);

    this.plane = new THREE.Mesh(this.geometry, this.material)

    this.scene.add(this.plane);



    const mesh  = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(4,0.5),
      new THREE.MeshBasicMaterial({color: 0xffff00})
    )



    this.clearPlane = new THREE.Mesh(
      new THREE.BoxBufferGeometry(2700, 2700),
      new THREE.MeshBasicMaterial({
        transparent: true,
        color: 0x000000,
        opacity: 0.1,
      })
    );

    this.scene1.add(this.clearPlane);
  }


  stop() {
    this.isPlaying = false;
  }

  play() {
    if(!this.isPlaying){
      this.isPlaying = true;
      this.render()

    }
  }

  render() {
    if (!this.isPlaying) return;

    this.renderer.setRenderTarget(this.baseTexture)
    this.renderer.render(this.scene1, this.camera1);
    this.material.uniforms.uTexture1.value =  this.baseTexture.texture
    this.renderer.setRenderTarget(null)
    this.renderer.clear()

    this.time += 0.05;
    this.material.uniforms.time.value = this.time;
    this.material.uniforms.progress.value = this.settings.progress;
    this.particlesMaterial.uniforms.time.value = this.time;
    this.particlesMaterial.uniforms.progress.value = this.settings.progress;
    this.particlesMaterial.uniforms.speed.value = this.settings.speed;
    this.material.uniforms.colorsProgress.value = this.settings.colorsProgress;


    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);




  }
}
