import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);


// ----------contacts----------//
function contactsInHd(){
   const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".contacts-section",
      start: "top bottom",
      end: "bottom 80%",
      scrub: 1,
      // markers: true,
      onScrubComplete: ({progress})=>{
        if(progress === 1){tl.kill(true);}
      }
    }
  });
  tl.from(".icon-amixy-text", {
      x: "-50%",
      left: "-100%",
      ease: "power1,inOut",
      duration: 2
    }, "0")
  tl.from(".contacts-section__anim-a", {
    y: 100,
    opacity: 0,
    stagger: 0.15,
    ease: "power1,inOut",
  }, "-=1.2")
  tl.from(".contacts-section__anim-b", {
    y: 100,
    opacity: 0,
    stagger: 0.15,
    ease: "power1,inOut",
  }, "-=1")
}

contactsInHd()








