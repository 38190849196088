import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animConsts  from "../constants";

gsap.registerPlugin(ScrollTrigger);

// -----flags-----//
let numerationsPlayed = false;
let sliderPlayed = false;
// ----flags-----//

// ----------numerations----------//
const numTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".lower__numeration",
    start: "top bottom",
    end: "top center",
    scrub: 1,
    // markers: true,
    refreshPriority: -1,
    onLeave:()=>{
      numerationsPlayed = true;
    },
    onScrubComplete: ({progress})=>{
      if(progress === 1){
        numTl.kill(true);
      }
    },
  }
});

function numerationsLg(){
  numTl.clear();
  gsap.set(".lower__numeration-item",{
    width: 255,
  })
  gsap.set(".lower__numeration",{
    clearProps: "all",
  })
  if(!numerationsPlayed){
    numTl.from(".lower__numeration", {
      width: "89%",
      x: 200,
      ease: "none",
    })
  }
}
function numerationsMd(screenWidth){
  let itemWidth = (screenWidth/2)-(screenWidth/10);
  if(screenWidth < animConsts.sm)itemWidth = (screenWidth/2)-(screenWidth/18);
  gsap.set(".lower__numeration-item",{
    width: itemWidth,
  })
  gsap.set(".lower__numeration",{
    clearProps: "all",
  })
  numTl.clear();
  if(!numerationsPlayed){
    numTl.from(".lower__numeration", {
      width: "89%",
      x: 200,
      ease: "none",
    })
  }
}

// -----------slider------------//
const TlLg = gsap.timeline({
  scrollTrigger: {
    trigger: ".section-title__swiper",
    start: "bottom bottom",
    end: "bottom center",
    scrub: 1,
    //  markers: true,
    refreshPriority: -1,
    onLeave:()=>{
      sliderPlayed = true;
    },
    onScrubComplete: ({progress})=>{
      if(progress === 1){
        TlLg.kill(true);
      }
    },
  }
});
function sliderIn(){
  gsap.set(".swiper-slide", {
   y: 0,
  })
  TlLg.clear();
  if(!sliderPlayed){
    TlLg.from(".swiper-slide", {
      stagger: 0.09,
      y: 120,
      ease: "none",
    })
  }
}

// ---------resize check-------//
function getSizeSliders(){
  
    if(animConsts.globalScreenX < animConsts.lg){
        numerationsMd(animConsts.globalScreenX);
        sliderIn()
    } else{
      numerationsLg(animConsts.globalScreenX)
      sliderIn()
    }
  }


  window.addEventListener('resize', getSizeSliders)
  document.addEventListener('DOMContentLoaded', getSizeSliders)






