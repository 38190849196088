import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import animConsts  from "../constants";
import { textSplit, liner } from "../helpers/textBlender"

gsap.registerPlugin(ScrollToPlugin);

// -----flags-----//
let hearoPlayed = false;
let videoPlaying = false;
let screenHd = true;

// ----flags-----//
const timeLine = document.querySelector(".hero-section__timeline");
timeLine.style.opacity = 0;
const videoClip = document.querySelector(".js-hero-video");
const menuBtnHd = document.querySelector(".menu__btn");
const menuBtnMd = document.querySelector(".menu__btn-mobile");




// --------global play----//
function playToX(){
  // --------fullScreen------//
  const action = gsap.timeline({defaults:{duration:0.6}})
  action.to('.menu__btn__play', {
  attr:{d:"M 25 13.5 C 25 15.5 25 13.5 25 15.5 L 12 15.5 C 9 15.5 1 15.5 1 15.5 L 1 13.5 C 1 13.5 9 13.5 12 13.5 L 25 13.5 Z"},
  duration: 0.2,
})
  action.to('.menu__btn__ico', {
  rotationZ:45,
   transformOrigin:'center'})
  action.to('.menu__anim_play-line', {rotationZ:-45, transformOrigin:'center'}, "<");
  // -------mobile-----//
  const actionM = gsap.timeline({defaults:{duration:0.6}})
  actionM.to('.menu__btn_mobile-play', {
  attr:{d:"M 25 13.5 C 25 15.5 25 13.5 25 15.5 L 12 15.5 C 9 15.5 1 15.5 1 15.5 L 1 13.5 C 1 13.5 9 13.5 12 13.5 L 25 13.5 Z"},
  duration: 0.2,
})
  actionM.to('.menu__btn__ico-mobile', {
  rotationZ:45,
   transformOrigin:'center'})
  actionM.to('.menu__anim_play-line-mobile', {
    rotationZ:-45,
    transformOrigin:'center'
  }, "<")
  // ------master-----//
  const masterToX = gsap.timeline();
      masterToX
      .add(action)
      .add(actionM, "<")
  return masterToX
}
function xToPlay(){
 // --------full screen------//
 const stop = gsap.timeline({defaults:{duration:0.6}})
 stop.to('.menu__btn__ico', {
   rotationZ:0,
   duration: 0.4,
 })
 stop.to('.menu__anim_play-line', {
   rotationZ:0,
   duration: 0.4,
 }, "<")
 stop.to('.menu__btn__play', {
   attr:{d:"M24 12.7679C25.3333 13.5378 25.3333 15.4622 24 16.232L3.75 27.9234C2.41666 28.6932 0.749999 27.7309 0.749999 26.1913L0.75 2.80865C0.75 1.26905 2.41667 0.306805 3.75 1.07661L24 12.7679Z"},
   duration: 0.3,
 }, "-=0.1");
 // --------mobile------//
 const stopM = gsap.timeline({defaults:{duration:0.6}})
 stop.to('.menu__btn__ico-mobile', {
   rotationZ:0,
   duration: 0.4,
 })
 stopM.to('.menu__anim_play-line-mobile', {
   rotationZ:0,
   duration: 0.4,
 }, "<")
 stopM.to('.menu__btn_mobile-play', {
   attr:{d:"M24 12.7679C25.3333 13.5378 25.3333 15.4622 24 16.232L3.75 27.9234C2.41666 28.6932 0.749999 27.7309 0.749999 26.1913L0.75 2.80865C0.75 1.26905 2.41667 0.306805 3.75 1.07661L24 12.7679Z"},
   duration: 0.3,
 }, "-=0.1")
 // ----master----//
 const masterToPlay = gsap.timeline();
    masterToPlay
      .add(stop)
      .add(stopM, "<")
  return masterToPlay
}
// --------hearo HD--------//


function hearoSetHd(){
    gsap.set(".menu__btn__layer-1, .menu__btn__layer-2",{left: -145});
    gsap.set(".menu",{width: "125%"});
}
function startHd(){
  if(hearoPlayed)return hearoPlayed
  const tl = gsap.timeline()
  tl.to(".menu", {
    width: "60%",
    ease: "power1.out",
    duration: 1,
    delay: 1,
  })
  tl.from(".header__menu", {
    x: 50,
    opacity: 0,
    ease: "power1.out",
    duration: 1
  },"<")
  tl.from(".menu__header", {
    x: 100,
    ease: "power1.out",
    duration: 1
  },"<")
  return tl;
}
function playerInHd(){
  if(hearoPlayed)return hearoPlayed
  const tl = gsap.timeline();
  tl.to(".menu__btn__layer-1",{
    left: 0,
    duration: 1,
    ease: "power3.out",
  })
  tl.to(".menu__btn__layer-2",{
    left: 0,
    duration: 1,
    ease: "power3.out",
  }, "-=0.85")
  tl.from(".menu__btn__layer-3, .menu__btn__ico",{
    left: -145,
    duration: 1,
    ease: "power3.out",
  }, "-=0.85")
  return tl
}
function textContentInHd(){
  if(hearoPlayed)return hearoPlayed
  const tl = gsap.timeline();
  tl.from(".menu__title",{
    x: 40,
    opacity: 0,
    duration: 1.05,
    ease: "circle"
  });
  tl.from(".menu__text_line",{
    y : "15px",
    opacity: 0,
    duration: 1,
    stagger: 0.1,
    ease: "power2.out"
  }, "<+=0.3");
  tl.from(".menu__point",{
    opacity: 0,
    paddingLeft: 30,
    duration: 1,
    ease: "power1.out"
  }, "<+=0.8");
  return tl
}
function playBtnHolder(){
  const whiteScreen = document.querySelector(".menu__body").getBoundingClientRect().width * -1;
  gsap.set(".menu__body", {left: whiteScreen,})
  gsap.set(".menu__btn_pushed",{left:whiteScreen})
}
function playerControllHd(direction=-1){
  const whiteScreen = document.querySelector(".menu__body").getBoundingClientRect().width * direction;
  const tl = gsap.timeline({defaults:{duration:1}})
  tl.to(".menu__body", {
    left: whiteScreen,
    ease: "power1.out",
  })
  tl.to(".menu__btn", {
    left: whiteScreen,
    ease: "power1.out",
  }, "<")
  if(direction === 0){
    gsap.set(".menu__content, .menu__header",{clearProps:"all"})
    tl.from(".menu__header", {
      width: "0%",
      ease: "power1.out",
    },"<")
    tl.from(".menu__content", {
      left: -400,
      opacity: 0,
      ease: "power1.out",
    }, "-=0.8")
    tl.to(timeLine, {
      opacity: 0,
      ease: "power1.in",
    }, "<")

  } else {
    tl.to(".menu__content", {
      left: -1200,
      opacity: 0,
      ease: "none",
    }, "<")
    tl.to(timeLine, {
      opacity: 1,
      ease: "power1.in",
    }, "<")
    tl.to(".menu__header", {
      width: "0%",
      ease: "power1.out",
    },"<")
  }
  return tl;
}
function menuPlayHd(){
  playToX().pause();
  xToPlay().pause();
  if(menuBtnHd.classList.contains("menu__btn_pushed") && videoPlaying){
    menuBtnHd.classList.remove("menu__btn_pushed");
    menuBtnMd.classList.remove("menu__btn_pushed");
    videoPlaying = false;
    videoClip.muted = true;
    xToPlay().resume();
    playerControllHd(0)
  } else if(!videoPlaying){
    videoPlaying = true;
    videoClip.muted = false;
    playToX().resume();
    playerControllHd();
    menuBtnHd.classList.add("menu__btn_pushed");
    menuBtnMd.classList.add("menu__btn_pushed");
  }
}




// --------hearo MD--------//


function hearoSetMd(){
  gsap.set(".menu__mobile-anim_layer-1, .menu__mobile-anim_layer-2, .menu__mobile-anim_layer-3, .menu__btn__ico",{left: -145});
  gsap.set(".menu",{height: "125%"});
}
function startMd(){
  if(hearoPlayed){return false}
  
  const tl = gsap.timeline()
  tl.to(".menu", {
    width: "100%",
    height: 360,
    ease: "power1.out",
    duration: 1,
    delay: 1,
  })
  return tl;
}
function playerInMd(){
  if(hearoPlayed){return false}
  
  const tl = gsap.timeline();
  tl.to(".menu__mobile-anim_layer-1",{
    left: 0,
    duration: 1,
    ease: "power3.out",
  })
  tl.to(".menu__mobile-anim_layer-2",{
    left: 0,
    duration: 1,
    ease: "power3.out",
  }, "-=0.85")
  tl.to(".menu__mobile-anim_layer-3",{
    left: 0,
    duration: 1,
    ease: "power3.out",
  }, "-=0.85")
  tl.to(".menu__btn__ico",{
    left: 15,
    duration: 1,
    ease: "power3.out",
  }, "<")
  return tl
}
function textContentInMd(){
  if(hearoPlayed)return true
  
  const tl = gsap.timeline();
    gsap.set(".menu__title, .menu__text, .menu__point",{clearProps:"all"})
    tl.from(".menu__title",{
      y: -100,
      duration: 1.05,
      ease: "circle"
    });
    tl.from(".menu__title",{
      opacity: 0,
      duration: 1.05,
      ease: "power1.in"
    }, "<");
    tl.from(".menu__text_line",{
      y : "-25px",
      opacity: 0,
      duration: 1.5,
      stagger: 0.2,
      ease: "power2.out"
    }, "<+=0.5");
    tl.from(".menu__point",{
      opacity: 0,
      paddingLeft: 30,
      duration: 1,
      ease: "power1.out"
    }, "<+=0.6");
    return tl
}
function playerHolderMd(){
  
  const whiteScreen = document.querySelector(".menu__body").getBoundingClientRect().height * -1;
  const tl = gsap.timeline({defaults:{duration:1}})
  tl.set(".menu__body", {
    top: whiteScreen,
  })
  tl.set(".menu__btn", {
    top: "50%",
  }, "<")
    tl.set(".menu__content", {
      top: -800,
      opacity: 0,
    }, "<")
    tl.set(timeLine, {
      opacity: 1,
    }, "<")
    tl.set(".menu__header", {
      opacity: 0,
    },"<")

  return tl;
}
function playerControllMd(direction=-1){
  const whiteScreen = document.querySelector(".menu__body").getBoundingClientRect().height * direction;
  
  const tl = gsap.timeline({defaults:{duration:1}})
  tl.to(".menu__body", {
    top: whiteScreen,
    ease: "power1.out",
  })
  tl.to(".menu__btn", {
    top: "50%",
    ease: "power1.out",
  }, "<")
  if(direction === 0){
    gsap.set(".menu__content, .menu__header",{clearProps:"all"})
    tl.from(".menu__header", {
      opacity: 0,
      ease: "power1.out",
    },"<")
    tl.from(".menu__content", {
      top: -400,
      opacity: 0,
      ease: "power1.out",
    }, "-=0.8")
    tl.to(timeLine, {
      opacity: 0,
      ease: "power1.in",
    }, "<")

  } else {
    tl.to(".menu__content", {
      top: -800,
      opacity: 0,
      ease: "none",
    }, "<")
    tl.to(timeLine, {
      opacity: 1,
      ease: "power1.in",
    }, "<")
    tl.to(".menu__header", {
      transformOrigin: "top top",
      opacity: 0,
      ease: "power1.out",
    },"<")
  }
  return tl;
}
function menuPlayMd(){
  
  playToX().pause();
  xToPlay().pause();

  if(menuBtnMd.classList.contains("menu__btn_pushed") && videoPlaying){
    menuBtnMd.classList.remove("menu__btn_pushed");
    menuBtnHd.classList.remove("menu__btn_pushed");
    videoPlaying = false;
    videoClip.muted = true;
    xToPlay().resume();
    playerControllMd(0)
  } else if(!videoPlaying){
    videoPlaying = true;
    videoClip.muted = false;
    playToX().resume();
    playerControllMd()
    menuBtnMd.classList.add("menu__btn_pushed");
    menuBtnHd.classList.add("menu__btn_pushed");
  }
}


// ---------resize check-------//
function getSizeHearo(){

    if(animConsts.globalScreenX < animConsts.md){
      menuBtnMd.addEventListener('click', menuPlayMd);
      if(videoPlaying){
        playerHolderMd()
        gsap.set(".menu__btn-mobile__wrapper>*",{clearProps:"all"})
      }
      if(!hearoPlayed){
        hearoSetMd()
      }else if(screenHd && !videoPlaying){
        screenHd = false;
        gsap.set(".menu__btn-mobile__wrapper>*, .menu, .menu__content>*, .menu__body, .menu__header",{clearProps:"all"})
      }

        const master = gsap.timeline();
        master
          .add(startMd())
          .add(()=>{hearoPlayed = true})
          .add(playerInMd(), "-=0.6")
          .add(textContentInMd(), "-=1.1")
    } else {
      menuBtnHd.addEventListener('click', menuPlayHd);
      if(videoPlaying){
        gsap.set(".menu, .menu__body, .menu__btn",{clearProps:"all"})
        playBtnHolder()
      }
      if(!hearoPlayed){
          hearoSetHd()
      }else if(!screenHd && !videoPlaying){
        screenHd = true;
        gsap.set(".menu, .header__menu, .menu__header, .menu__content>*, .menu__body, .menu__btn",{clearProps:"all"})
      }
      const master = gsap.timeline();
      master
      .add(startHd())
      .add(()=>{hearoPlayed = true}, "<")
      .add(playerInHd(), "-=0.6")
      .add(textContentInHd(), "-=1.1")
    }
  }

function onStart(){
  textSplit(".menu__text", "menu__text_word")
        .then(()=>{liner(".menu__text", 2, "menu__text_line")}).then(()=>{
          getSizeHearo()
        })
}
window.addEventListener('resize', getSizeHearo)
document.addEventListener('DOMContentLoaded', onStart)
