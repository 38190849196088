import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import animConsts  from "../../constants";
import goUpThenRun from "../../helpers/scrollToTop";
import { textSplit, liner } from "../../helpers/textBlender"
import Sketch from "../../circle3d/app3d";

gsap.registerPlugin(ScrollTrigger);


const bublik = document.querySelector("#bublic__screen");
const block = document.querySelector(".circle__pin");
const videoCircle = document.querySelector(".features__circle-mobile_wrapper").children[0];
const videoStrip = document.querySelector(".applications-section__strip-mobile_wrapper").children[0];
const clamp = gsap.utils.clamp(-10, 10);
const veloClamp = gsap.utils.clamp(0.5, 1.5);

// -->Flags<--//
let wideScreen = true;
let stripPhase = false;
let stripScale = window.innerWidth / bublik.childNodes[0].width;
let phaseBProgress = 0;
let phaseCProgress = 0;
let scrollVelocity = 1;
let circleBlockDone = false;



// --> mobile bublik video functions
function injectsources(parrent,videoList,sourceClass){
  for(let i=0; i<videoList.length; i++){
      const source = document.createElement('source');
      source.setAttribute('src', videoList[i][0]);
      source.setAttribute('type', videoList[i][1]);
      source.classList.add(sourceClass);
      parrent.appendChild(source);
  }
  parrent.load()
  parrent.play();
}
function removeSources(sourceClass){
    const mySrc = document.getElementsByClassName(sourceClass);
    for(let i = mySrc.length-1; i>=0; i--){
      mySrc[i].remove();
    }
}

// -->bublik creation and settings<--//
    const circle = new Sketch({
      dom: document.getElementById("bublic__screen"),
    });
    circle.settings.progress = 1;
    circle.settings.colorsProgress = 0.5;
    circle.stop();

// --> wide screen bublik controll
const CircleSwitch = ScrollTrigger.create({
  trigger: block,
  start: "top bottom",
  end: "bottom+=400px top",
  scrub: true,
  // markers: true,
  onEnter(){
    circle.play()
  },
  onEnterBack(){
    circle.play()
  },
  onLeave(){
    circle.stop()
  },
  onLeaveBack(){
    circle.stop()
  }
})

// gsap animation roots


function wideScreenAnimationA(){
  const phaseA = gsap.timeline({
    scrollTrigger:{
      trigger:".info-section__strip",
      // trigger:".circle-section__clarification",
      start: "top+=45px top",
      end: "+=100",
      // pin: ".circle__pin",
      // markers: true,
      // scrub: 1,
      once: true,
      onUpdate(self){
        scrollVelocity = veloClamp(Math.abs(clamp(self.getVelocity() / -300)));
        phaseA.timeScale(scrollVelocity)
      }
    },
  })
  phaseA.from("#container",{
    scale: 0,
    opacity: 0,
    duration: 1,
    ease: "circle",
  }, )
  phaseA.from(".features__blue-logo",{
    opacity: 0,
    duration: 1,
    ease: "power1.out"
  }, "<+=0.5")
  phaseA.from(".features__circle-border",{
    scale: 0.8,
    opacity: 0,
    duration: 1,
    ease: "back.out(1)",
  }, "<")
  return phaseA
}
let animBEnd = false
function wideScreenAnimationB(){
  const phaseB = gsap.timeline({
    scrollTrigger:{
      trigger:".circle-section__clarification",
      start: "top top",
      end: "top+=240 top",
      // markers: true,
      once: true,
      onEnter(){
        wideScreenAnimationA().progress(1)
      },
      onUpdate(self){
        scrollVelocity = veloClamp(Math.abs(clamp(self.getVelocity() / -300)));
        phaseB.timeScale(scrollVelocity)
        if(self.progress === 1)animBEnd = true;
      }
    },
    onUpdate(){
      phaseBProgress = this.progress()
      if(circleBlockDone){this.progress(1)}
      if(phaseBProgress === 1 && !animBEnd){
        animBEnd = true;
        window.scrollTo(0, this.scrollTrigger.start + 240)
        }
    }
  })
  phaseB.from("#features__anim_block-a_stroke",{
    strokeDashoffset: 230,
    duration: 0.6,
  }, "<")
  phaseB.from("#features__anim_block-a_step-a",{
    scale: 0,
    opacity: 0,
    duration: 0.25,
  }, "<+=0.33")
  phaseB.from("#features__anim_block-a_step-b",{
    x: -10,
    opacity: 0,
    duration: 0.25,
  }, "<")
   phaseB.from("#features__anim_block-a_step-c > *",{
    y:50,
    opacity: 0,
    duration: 0.4,
    stagger: 0.05,
  }, "<+=0.05")
  phaseB.from("#features__anim_block-b_stroke",{
    strokeDashoffset: 230,
    duration: 0.5,
  },"<")
  phaseB.from("#features__anim_block-b_step-a",{
    scale: 0,
    opacity: 0,
    duration: 0.25,
  }, "<+=0.25")
  phaseB.from("#features__anim_block-b_step-b",{
    x: -10,
    opacity: 0,
    duration: 0.25,
  }, "<")
   phaseB.from("#features__anim_block-b_step-c > *",{
    y:50,
    opacity: 0,
    duration: 0.4,
    stagger: 0.05,
  }, "<+=0.05")
  phaseB.from("#features__anim_block-c_stroke",{
    strokeDashoffset: 230,
    duration: 0.6,
  },"<")
  phaseB.from("#features__anim_block-c_step-a",{
    scale: 0,
    opacity: 0,
    duration: 0.25,
  }, "<+=0.25")
  phaseB.from("#features__anim_block-c_step-b",{
    x: -10,
    opacity: 0,
    duration: 0.25,
  }, "<")
   phaseB.from("#features__anim_block-c_step-c > *",{
    y:50,
    opacity: 0,
    duration: 0.4,
    stagger: 0.05,
  }, "<+=0.05")
  phaseB.addLabel("fin", "+=1")
  return phaseB
}
let scenaryEnd = false;
function wideScreenAnimationC(){
  const phaseC = gsap.timeline({
    scrollTrigger:{
      trigger:".circle-section__clarification",
      start: "top+=250px top",
      end: "top+=750px top",
      once: true,
      // markers: true,
      onEnter(){
        if(phaseBProgress < 1){wideScreenAnimationB().progress(1)}
      },
      onUpdate(self){
        scrollVelocity = veloClamp(Math.abs(clamp(self.getVelocity() / -300)));
        phaseC.timeScale(1)
        if(self.progress === 1)scenaryEnd = true;
      }
    },
    onUpdate(){
      phaseCProgress = this.progress();
      if(this.progress() > 0.2){gsap.set(".features__stroke, .features__circle-border, .features__blue-logo",{opacity: 0})}
      if(circleBlockDone){this.progress(1)}
      if(phaseCProgress === 1 && !scenaryEnd){
        scenaryEnd = true;
        window.scrollTo(0, this.scrollTrigger.end - 10)
        }
    }
  })
 phaseC.to(".features__stroke",{
  strokeDashoffset: -230,
  duration: 0.6,
})
phaseC.to(".features__circle-border",{
  scale: 0.6,
  opacity: 0,
  duration: 1,
  ease: "power1.in",
}, "<")
phaseC.to(".features__text-anim_left",{
  x: "-100",
  opacity: 0,
  stagger: 0.1,
  duration: 0.6,
  ease: "power1.in"
}, "<+=0.2")
phaseC.to(".features__text-anim_right",{
  x: "100",
  opacity: 0,
  duration: 0.4,
  ease: "power1.in",
}, "<+=0.1")
phaseC.to(".features__letter-round_white",{
  opacity: 0,
  duration: 0.5,
  ease: "power1.out",
}, "<")
// --circle transformation-->
phaseC.to("#container",{
  y: -44,
  duration: 1,
  ease: "power1.out",
  onUpdate(){
    const prog = (this.progress() - 1) * -1;
    const colorProg = prog * 0.5
    circle.settings.progress = prog;
    circle.settings.colorsProgress = colorProg;
    if(prog < 0.5){stripPhase = true}else{stripPhase = false}
  }
},"<")
  phaseC.to("#container canvas",{ // circle and strip scale controll
    duration: 1,
    onUpdate(){
      const phase = this.progress();
      const myScale = (this.progress() * (window.innerWidth / this.targets()[0].width - 1)) + 1;
      let correctedScale = myScale;
        if(phase > 0.5){correctedScale = myScale + phase * 0.1}
        if(phase < 0.5){correctedScale = myScale}
        this.targets()[0].style.transform = `scaleX(${correctedScale})`;
    },
    ease: "power1.in",
  },"<+=0.5")

phaseC.to(".features__shadow",{
  opacity: 0,
  duration: 0.5,
  ease: "power1.out",
},"<-=0.5")
phaseC.to(".features__blue-logo",{
  opacity: 0,
  duration: 1,
  ease: "power1.in"
}, "<-=0.5")
phaseC.from(".applications-section__img-wrapper",{ // white logo
  opacity: 0,
  duration: 1,
  ease: "power1.out"
}, "<+=1")
// --stip on/\/\/\
phaseC.to(".circle-section__clarification",{
  y: -100,
  opacity: 0,
  duration: 0.5,
  ease: "power1.out"
}, ">-=0.5")
phaseC.from(".features__anim_block-d_step-a",{
  y: 100,
  opacity: 0,
  duration: 0.5,
  ease: "power1.out"
}, "<+=0.2")
phaseC.to(".features__anim_block-d_step-b",{
  y: -100,
  opacity: 0,
  duration: 0.5,
  ease: "power1.out"
}, "<+=0.2")
phaseC.from(".applications-section__herizontal-line_top" ,{
  scaleX: 0,
  duration: 1,
  ease: "power1.out"
}, )
phaseC.from(".applications-section__shadow-caster",{// ------------------->
  opacity: 0,
  duration: 1,
  ease: "none"
}, "<")// ------------------->
phaseC.from(".applications-section__herizontal-line_bottom" ,{
  scaleX: 0,
  duration: 1,
  ease: "power1.out"
}, "<+=0.1")
phaseC.from(".applications-section__anim_block-a_step-a" ,{
  opacity: 0,
  scale: 0,
  duration: 0.4,
  ease: "power1.out"
}, "<")
phaseC.from(".applications-section__anim_block-a_step-b" ,{
  scaleY: 0,
  duration: 0.3,
  ease: "power1.out"
}, "<+=0.2")
phaseC.from(".applications-section__anim_block-b_step-a" ,{
  opacity: 0,
  scale: 0,
  duration: 0.4,
  ease: "power1.out"
}, "<")
phaseC.from(".applications-section__anim_block-b_step-b" ,{
  scaleY: 0,
  duration: 0.3,
  ease: "power1.out"
}, "<+=0.2")
phaseC.from(".applications-section__anim_block-c_step-a" ,{
  opacity: 0,
  scale: 0,
  duration: 0.4,
  ease: "power1.out"
}, "<")
phaseC.from(".applications-section__anim_block-c_step-b" ,{
  scaleY: 0,
  duration: 0.3,
  ease: "power1.out"
}, "<+=0.2")
phaseC.from(".applications-section__container_white" ,{
  opacity: 0,
  scale: 0,
  stagger: 0.33,
  duration: 1,
}, "<-=0.1")
phaseC.addLabel("fin", "+=1")
  return phaseC
}
function wideScreenBlockBack(){
  wideScreenAnimationB().progress(1)
  wideScreenAnimationC().progress(1)
  setTimeout(()=>{
    gsap.set(".features__stroke, .features__circle-border, .features__blue-logo, .features__text-block",{opacity: 1});
    gsap.set(block,{clearProps: "margin"});
  },100)
  const myDuration = 0;
  const blockBack = gsap.timeline();
  blockBack.to(".features__anim_block-d_step-b",{
    y: 0,
    opacity: 1,
    duration: myDuration
  })
  blockBack.to(".features__anim_to-hide",{
    y: -780,
    opacity: 1,
    duration: myDuration
  }, "<")
  blockBack.to(".circle-section__clarification",{
    y: -780,
    opacity: 1,
    duration: myDuration
  }, "<")
  blockBack.to(".features__pinters",{
    y: -780,
    opacity: 1,
    duration: myDuration
  }, "<")
  blockBack.to(".features__stroke",{
    strokeDashoffset: 0,
    opacity: 1,
    duration: myDuration
  }, "<")
  blockBack.to(".features__circle-border",{
    scale: 1,
    y: -800,
    opacity: 1,
    duration: myDuration
  }, "<")
  blockBack.to(".features__text-anim_left",{
    x: "0",
    y: -780,
    opacity: 1,
    duration: myDuration
  }, "<")
  blockBack.to(".features__text-anim_right",{
    x: 25,
    y: -780,
    opacity: 1,
    duration: myDuration
  }, "<")
  blockBack.to(".features__letter-round_white",{
    opacity: 1,
    y: -756,
    duration: myDuration
  }, "<") 
  blockBack.to(".features__shadow",{
    opacity: 1,
    y: -780,
    duration: myDuration
  },"<")
  blockBack.to(".features__blue-logo",{
    opacity: 1,
    y: -750,
    duration: myDuration
  }, "<")
  blockBack.to(".features__circle-mobile_wrapper",{
    opacity: 1,
    y: -650,
    width: 510,
    height:510,
    duration: myDuration
  }, "<")
  blockBack.to(".features__circle-mobile_wrapper > video",{
    opacity: 1,
    scale: 1.0,
    duration: myDuration
  }, "<")
  return blockBack
}

function circleInupperHd(){
  const upperHd = gsap.timeline();
  upperHd.set(".features__circle-mobile_wrapper",{
    y: -650,
    scale: 1
  })
  upperHd.set(".features__circle-border",{
    y: -800,
  })
  return upperHd;
}
function circleInLowerHd(){
  const lowerHd = gsap.timeline();
  lowerHd.set(".features__circle-mobile_wrapper",{
    y: -688,
    scale: 0.92
  })
  lowerHd.set(".features__circle-border",{
    y: -796,
  })
  lowerHd.set(".pin-spacer",{
    width: "100%",
    margin: "auto"
  })
  return lowerHd;
}
function finalCircleControll(){
  videoCircle.play();
  if(circleBlockDone){
    if(animConsts.globalScreenX <= animConsts.circleHd){
      circleInLowerHd()
    } else {
      circleInupperHd()
    }
  }
}

function wideScreenAnimationControll(){
  removeSources("video_src")
  injectsources(videoCircle, animConsts.videoCircleWideScreenSources, "video_src");
  document.querySelector(".features__circle-mobile_wrapper > video").style.opacity = 0;
  textSplit(".features__text", "features__word")
  .then(()=>{liner(".features__text", 2, "features__line");})
  .then(()=>{
     wideScreenAnimationA().then(
      wideScreenAnimationB().then(
          wideScreenAnimationC().seek(0).then(
            ()=>{
              if(phaseCProgress > 0.2){gsap.set(".features__stroke, .features__circle-border, .features__blue-logo",{opacity: 0})}
              }
          )
        )
     )
    
    ScrollTrigger.create({
      trigger:".circle-section__clarification",
      start: "top-=15px top",
      end: "+=785px",
      pin: ".circle__pin",
      anticipatePin: 1,
      once: true,
      // markers: true,
      onUpdate: (self)=>{
      if(self.progress === 1){
        circleBlockDone = true;
        const box = document.querySelector(".pin-spacer");
      setTimeout(()=>{
        ScrollTrigger.refresh()
        if(box !== undefined){
          gsap.set(box,{clearProps: "width, margin"})
        }
        wideScreenBlockBack()
        finalCircleControll() 
    },1)
    }
  },
    })
  })
}

function narrowAnimations(){
  const futureTlMd = gsap.timeline({
    scrollTrigger:{
      trigger: "#container",
      start: "10% 10%",
      end: "bottom+=100px top",
      scrub: 1,
    },
  })
  futureTlMd.from("#features__anim_block-a_step-a",{
    scale: 0,
    opacity: 0,
    duration: 0.33,
  }, "<+=0.33")
  futureTlMd.from("#features__anim_block-a_step-b",{
    x: -10,
    opacity: 0,
    duration: 0.33,
  }, "<")
  futureTlMd.from("#features__anim_block-a_step-c > *",{
    y:50,
    opacity: 0,
    duration: 1,
    stagger: 0.2,
  }, "<+=0.05");
  futureTlMd.from("#features__anim_block-c_step-a",{
    scale: 0,
    opacity: 0,
    duration: 0.33,
  }, ">")
  futureTlMd.from("#features__anim_block-c_step-b",{
    x: -10,
    opacity: 0,
    duration: 0.33,
  }, "<")
  futureTlMd.from("#features__anim_block-c_step-c > *",{
    y:50,
    opacity: 0,
    duration: 1,
    stagger: 0.2,
  }, "<+=0.05");
  futureTlMd.from("#features__anim_block-b_step-a",{
    scale: 0,
    opacity: 0,
    duration: 0.33,
  }, ">")
  futureTlMd.from("#features__anim_block-b_step-b",{
    x: -10,
    opacity: 0,
    duration: 0.33,
  }, "<")
  futureTlMd.from("#features__anim_block-b_step-c > *",{
    y:50,
    opacity: 0,
    duration: 1,
    stagger: 0.2,
  }, "<+=0.05");
  return futureTlMd;
}

function narrowScreenSettings(){
  CircleSwitch.kill(true);
  circle.stop();
  injectsources(videoCircle, animConsts.videoCircleSources, "video_src");
  injectsources(videoStrip, animConsts.videoStripSources, "video_src");
  textSplit(".features__text", "features__word")
  .then(()=>{liner(".features__text", 2, "features__line");})
  .then(()=>{
    narrowAnimations()
  });
}

function start(){
  if(animConsts.globalScreenX >= animConsts.md){
    wideScreen = true;
    wideScreenAnimationControll()
  } else {
    wideScreen = false;
    narrowScreenSettings()

  }
}

function resize(){
  if(animConsts.globalScreenX < animConsts.md){
    setTimeout(()=>{finalCircleControll()},1)
    // lineSplit(".features__text", ".features__line")
    // .then(()=>{
    //   liner(".features__text", 2, "features__line");}).then(()=>{
    //   narrowAnimations()
    // })
      if(wideScreen){
      setTimeout(()=>{window.location.reload(false);},1)
      narrowScreenSettings()
      wideScreen = false;
    }
  }else{
    setTimeout(()=>{finalCircleControll()},1)
    if(bublik.children[1] && stripPhase){
      const myCanvas = bublik.querySelector("canvas")
      stripScale = (animConsts.globalScreenX / myCanvas.width) + 0.1;
      myCanvas.style.transform = `scaleX(${stripScale})`
    }
    if(!wideScreen){
      setTimeout(()=>{window.location.reload(false);},1)
      wideScreenAnimationControll()
      wideScreen = true;
    }
  }
}
window.addEventListener('resize', resize)
document.addEventListener('DOMContentLoaded', goUpThenRun.call(this, start))




