

const site = document.querySelector(".out");
const preloader = document.querySelector(".preloader");
site.style.opacity = 0;

// flage-->
let contentLoaded = false;
let alreadyLoaded = false

function loading(){
  if(alreadyLoaded)return
  preloader.classList.add("fade_out")
  site.style.opacity = 1;
  alreadyLoaded = true;
  setTimeout(()=>{
    preloader.remove();
  },1000);
}

document.addEventListener('DOMContentLoaded', ()=>{contentLoaded = true})

document.addEventListener('readystatechange', event => { 
 if(event.target.readyState === "complete"){
   loading();
 } else {
   setTimeout(()=>{
     if(contentLoaded){loading()}
   },4000);
   setTimeout(()=>{
    loading();
  },8000);
 }
})

