export default function goUpThenRun(callback){
  if(window.scrollY !== 0){
    window.scrollY = 0
    return callback;
  }
    return callback;

}


